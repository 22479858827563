import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
  Container,
  MainInfo,
  ItineraryVitrine,
  AddressField,
  Contact,
  EventInfo,
  MapAndVideo,
  ExtraInfo,
  VistingHoursDetail,
  MainItinerary,
  Activities,
} from './styles';
import SelectComponent from 'components/Select';
import FieldMask from 'components/FieldMask';
import Button from 'components/Button';
import UploadImage from 'components/UploadImage';
import ConfirmationModal from 'components/ConfirmationModal';
import { getLocalUser } from 'services/localStorage';
import { useLoading } from 'hooks/useLoading';
import questionMarkIcon from 'assets/images/questionMarkIcon.svg';
import IFormSetur from 'interfaces/IFormSetur';
import { states, daysOfWeek, getValidationSchema } from './utils';
import Input from 'components/Input/Input';
import { placeIDLink } from 'utils/placeIDLink';

interface DynamicFormProps {
  formName: string;
  fields: string[];
  onSubmit: (values: IFormSetur) => Promise<void>;
  onCancel: () => void;
  values?: IFormSetur;
  categories: string[];
  itineraryList?: {
    value: string;
    label: string;
  }[];
}

const DynamicForm: React.FC<DynamicFormProps> = ({
  categories,
  formName,
  fields,
  onSubmit,
  onCancel,
  values,
  itineraryList,
}): JSX.Element => {
  const eventStates = states.map(option => ({ label: option, value: option }));
  const eventCategoryOptions = categories.map(option => ({ label: option, value: option }));
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { openLoading, closeLoading } = useLoading();
  const [enableMainItinerary, setEnableMainItinerary] = useState(false);
  const { profile } = getLocalUser();

  useEffect(() => {
    if (formik.values.itineraryId) {
      setEnableMainItinerary(true);
    }
  }, [values]);

  const formik = useFormik<IFormSetur>({
    enableReinitialize: true,
    initialValues: {
      id: 0,
      title: '',
      slug: '',
      category: [],
      activities: '',
      mainItinerary: '',
      description: '',
      place: '',
      price: '',
      link: '',
      address: '',
      addressInfo: {
        zipCode: '',
        street: '',
        number: '',
        neighborhood: '',
        additionalData: '',
        city: '',
        state: '',
      },
      placeId: '',
      youtubeLink: '',
      site: '',
      phone: '',
      whatsapp: '',
      instagramLink: '',
      facebookLink: '',
      email: '',
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      openingHours: '',
      openingHoursDetail: [],
      openTime: [],
      closingTime: [],
      isClosed: [],
      date: '',
      images: [],
      banners: [],
      image: [],
      attachmentMap: '',
      ...values,
    },
    validationSchema: getValidationSchema(formName),
    onSubmit: values => {
      if (profile.name === 'trade') {
        setShowConfirmModal(true);
        return;
      }

      openLoading();
      onSubmit(values).finally(closeLoading);
    },
  });

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTitle = e.target.value;
    const newSlug = newTitle.replace(/\s+/g, '-').toLowerCase();
    formik.handleChange(e);
    formik.setFieldValue('slug', newSlug);
  };

  const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAddress = e.target.value;
    const newStreet = newAddress.toLowerCase();
    formik.setFieldValue('addressInfo.street', newStreet);
    formik.validateField('address');
  };

  const handleCheckboxChange = (index: number) => {
    const openTimeValue = formik.values?.openTime?.[index] || '';
    const closingTimeValue = formik.values?.closingTime?.[index] || '';

    if (!openTimeValue && !closingTimeValue) {
      formik.setFieldValue(`isClosed[${index}]`, !formik.values?.isClosed?.[index]);
    } else {
      formik.setFieldValue(`openTime[${index}]`, '');
      formik.setFieldValue(`closingTime[${index}]`, '');
    }
  };

  const errorMessage = (name: string) => {
    const { touched, error } = formik.getFieldMeta(name);
    return touched && error && <p className="error">{error}</p>;
  };

  const fieldComponents: { [key: string]: React.ReactNode } = {
    MainItinerary: (
      <MainItinerary>
        <div className="radio-option">
          <label>
            Deseja vincular esse roteiro a um roteiro principal? <span>*</span>
          </label>
          <div className="radio-option-group">
            <div className="radio-option-group-item">
              <Input
                type="radio"
                name="haveMainItinerary"
                id="haveMainItinerary"
                className="radio-button"
                onChange={() => {
                  setEnableMainItinerary(false);
                  formik.setFieldValue('mainItinerary', '');
                }}
                checked={!enableMainItinerary}
              />
              <label htmlFor="noOption" id="noOption" className="radio-option-button">
                Não
              </label>
            </div>
            <div className="radio-option-group-item">
              <Input
                type="radio"
                name="haveMainItinerary"
                id="haveMainItinerary"
                className="radio-button"
                onChange={() => setEnableMainItinerary(true)}
                checked={enableMainItinerary}
              />
              <label htmlFor="yesOption" id="yesOption" className="radio-option-button">
                Sim
              </label>
            </div>
          </div>
          {errorMessage('radioOption')}
        </div>
        <div className="mainItinerary">
          {enableMainItinerary && (
            <>
              <SelectComponent
                label="Roteiro Principal"
                options={itineraryList ?? []}
                onChange={value => formik.setFieldValue('mainItinerary', value)}
                name="mainItinerary"
                selected={formik.values.mainItinerary ? [formik.values.mainItinerary] : []}
                disabled={!enableMainItinerary}
                placeholderText="Escolha seu roteiro principal"
              />
              {errorMessage('mainItinerary')}
            </>
          )}
        </div>
        <div className="dashedLine" />
      </MainItinerary>
    ),
    MainInfo: (
      <MainInfo>
        <div className="title">
          <label>
            Nome do {formName === 'Novo Roteiro' ? 'Roteiro' : 'Evento'} <span>*</span>
          </label>
          <input type="text" name="title" value={formik.values?.title ?? ''} onChange={handleChangeTitle} />
          {errorMessage('title')}
        </div>
        <div className="category">
          <SelectComponent
            label="Categorias"
            options={eventCategoryOptions}
            onChange={value => formik.setFieldValue('category', value)}
            name="category"
            withCheckbox
            selected={formik.values.category}
          />
          {errorMessage('category')}
        </div>
        <div className="description">
          <label>
            Descrição <span>*</span>
          </label>
          <textarea name="description" value={formik.values.description} onChange={formik.handleChange} />
          {errorMessage('description')}
        </div>
      </MainInfo>
    ),
    Activities: (
      <Activities>
        <div className="activities">
          <label>Atividades e Experiências (escreva um item por linha)</label>
          <textarea name="activities" value={formik.values.activities} onChange={formik.handleChange} />
          {errorMessage('activities')}
        </div>
      </Activities>
    ),
    AddressField: (
      <AddressField>
        <div className="postalCode">
          <label>
            CEP <span>*</span>
          </label>
          <FieldMask type="zipcode">
            <input
              type="text"
              name="addressInfo.zipCode"
              value={formik.values.addressInfo?.zipCode}
              onChange={formik.handleChange}
            />
          </FieldMask>
          {errorMessage('addressInfo.zipCode')}
        </div>
        <div className="address">
          <label>
            Endereço <span>*</span>
          </label>
          <input
            type="text"
            name="address"
            value={formik.values?.address}
            onChange={formik.handleChange}
            onBlur={handleChangeAddress}
          />
          {errorMessage('address')}
        </div>
        <div className="additionalData">
          <label>Complemento</label>
          <input
            type="text"
            name="addressInfo.additionalData"
            value={formik.values.addressInfo?.additionalData}
            onChange={formik.handleChange}
          />
        </div>
        <div className="neighborhood">
          <label>
            Bairro <span>*</span>
          </label>
          <input
            type="text"
            name="addressInfo.neighborhood"
            value={formik.values.addressInfo?.neighborhood}
            onChange={formik.handleChange}
          />
          {errorMessage('addressInfo.neighborhood')}
        </div>
        <div className="number">
          <label>
            Número <span>*</span>
          </label>
          <input
            type="text"
            name="addressInfo.number"
            value={formik.values.addressInfo?.number}
            onChange={formik.handleChange}
          />
          {errorMessage('addressInfo.number')}
        </div>
        <div className="state">
          <SelectComponent
            label="Estado"
            options={eventStates}
            onChange={value => formik.setFieldValue('addressInfo.state', value)}
            name="addressInfo.state"
            selected={formik.values.addressInfo?.state ? [formik.values.addressInfo?.state] : []}
          />
          {errorMessage('addressInfo.state')}
        </div>
        <div className="city">
          <label>
            Cidade <span>*</span>
          </label>
          <input
            type="text"
            name="addressInfo.city"
            value={formik.values.addressInfo?.city}
            onChange={formik.handleChange}
          />
          {errorMessage('addressInfo.city')}
        </div>
      </AddressField>
    ),
    Contact: (
      <Contact>
        <div className="phone">
          <label>
            Telefone <span>*</span>
          </label>
          <FieldMask type="phone">
            <input type="text" name="phone" value={formik.values.phone} onChange={formik.handleChange} />
          </FieldMask>
          {errorMessage('phone')}
        </div>
        <div className="whatsapp">
          <label>Whatsapp</label>
          <FieldMask type="phone">
            <input type="text" name="whatsapp" value={formik.values.whatsapp} onChange={formik.handleChange} />
          </FieldMask>
        </div>
        <div className="email">
          <label>E-mail</label>
          <input type="text" name="email" value={formik.values.email} onChange={formik.handleChange} />
          {errorMessage('email')}
        </div>
        <div className="instagramLink">
          <label>Link do Instagram</label>
          <input type="text" name="instagramLink" value={formik.values.instagramLink} onChange={formik.handleChange} />
        </div>
        <div className="facebookLink">
          <label>Link do Facebook</label>
          <input type="text" name="facebookLink" value={formik.values.facebookLink} onChange={formik.handleChange} />
        </div>
      </Contact>
    ),
    MapAndVideo: (
      <MapAndVideo>
        <div className="youtubeLink">
          <label>Link do Vídeo do Youtube</label>
          <input type="text" name="youtubeLink" value={formik.values.youtubeLink} onChange={formik.handleChange} />
        </div>
        <div className="placeId">
          <label style={{ display: 'flex', gap: '5px' }}>
            Place ID do Google Maps <span>*</span>
            <a href={placeIDLink} target="_blank" rel="noreferrer">
              <img src={questionMarkIcon} alt="Mais informações" />
            </a>
          </label>
          <input type="text" name="placeId" value={formik.values.placeId} onChange={formik.handleChange} />
          {errorMessage('placeId')}
        </div>
      </MapAndVideo>
    ),
    EventInfo: (
      <EventInfo>
        <div className="place">
          <label>
            Nome do Estabelecimento <span>*</span>
          </label>
          <input type="text" name="place" value={formik.values.place} onChange={formik.handleChange} />
          {errorMessage('place')}
        </div>
        <div className="eventLink">
          <label>Link do Evento</label>
          <input type="text" name="link" value={formik.values.link} onChange={formik.handleChange} />
        </div>
        <div className="site">
          <label>Link da Organização</label>
          <input type="text" name="site" value={formik.values.site} onChange={formik.handleChange} />
        </div>
        <div className="eventValue">
          <label>
            Valor do Evento <span>*</span>
          </label>
          <FieldMask type="currency">
            <input type="text" name="price" value={formik.values.price} onChange={formik.handleChange} />
          </FieldMask>
          {errorMessage('price')}
        </div>
        <div className="startDate">
          <label>
            Data de Início <span>*</span>
          </label>
          <FieldMask type="date">
            <input type="text" name="startDate" value={formik.values.startDate} onChange={formik.handleChange} />
          </FieldMask>
          {errorMessage('startDate')}
        </div>
        <div className="endDate">
          <label>
            Data de Fim <span>*</span>
          </label>
          <FieldMask type="date">
            <input type="text" name="endDate" value={formik.values.endDate} onChange={formik.handleChange} />
          </FieldMask>
          {errorMessage('endDate')}
        </div>
        <div className="startTime">
          <label>
            Hora de Início <span>*</span>
          </label>
          <FieldMask type="time">
            <input type="text" name="startTime" value={formik.values.startTime} onChange={formik.handleChange} />
          </FieldMask>
          {errorMessage('startTime')}
        </div>
        <div className="endTime">
          <label>
            Hora de Fim <span>*</span>
          </label>
          <FieldMask type="time">
            <input type="text" name="endTime" value={formik.values.endTime} onChange={formik.handleChange} />
          </FieldMask>
          {errorMessage('endTime')}
        </div>
      </EventInfo>
    ),
    ItineraryVitrine: (
      <ItineraryVitrine>
        <input type="checkbox" />
        <label>Criar Vitrine</label>
      </ItineraryVitrine>
    ),
    VistingHoursDetail: (
      <VistingHoursDetail>
        <div className="visitingHours">
          <label>Horário de Visitação</label>
          {daysOfWeek.map((day, index) => (
            <div className="daysWeek" key={day}>
              <label>{day}</label>
              <div className="openTime">
                <FieldMask type="time">
                  <input
                    type="text"
                    name={`openTime[${index}]`}
                    value={formik.values?.openTime?.[index] ?? ''}
                    onChange={formik.handleChange}
                    disabled={formik.values?.isClosed?.[index]}
                  />
                </FieldMask>
              </div>
              <div className="closingTime">
                <FieldMask type="time">
                  <input
                    type="text"
                    name={`closingTime[${index}]`}
                    value={formik.values?.closingTime?.[index] ?? ''}
                    onChange={formik.handleChange}
                    disabled={formik.values?.isClosed?.[index]}
                  />
                </FieldMask>
              </div>
              <div className="closedCheck">
                <input
                  type="checkbox"
                  name={`isClosed[${index}]`}
                  checked={formik.values?.isClosed?.[index]}
                  onChange={() => handleCheckboxChange(index)}
                />
                <label>Fechado</label>
              </div>
            </div>
          ))}
        </div>
      </VistingHoursDetail>
    ),
    ExtraInfo: (
      <ExtraInfo>
        <div className="slug">
          <label>Slug</label>
          <input type="text" name="slug" value={formik.values.slug} onChange={formik.handleChange} />
        </div>

        <div className="street">
          <label>Street</label>
          <input
            type="text"
            name="addressInfo.street"
            value={formik.values.addressInfo?.street}
            onChange={formik.handleChange}
          />
        </div>
      </ExtraInfo>
    ),
    UploadImageCard: (
      <>
        <UploadImage
          importedFiles={
            formik.values.image
              ?.filter((file: any) => {
                return typeof file === 'string';
              })
              ?.map((url: string, id: number) => {
                return { id, url, name: '' };
              }) ?? []
          }
          onChange={files => {
            const newFiles = files.map(file => {
              return 'url' in file ? file.url : file;
            });

            formik.setFieldValue('image', newFiles);
          }}
          descriptionText="Imagem do Card: Adicione 1 imagem. Para garantir a qualidade da visualização, as imagens precisam ter dimensão mínima de 500 x 600 pixels"
          multipleFiles={false}
          htmlFor="resource-image"
          limitFiles={1}
          required
        />
        {errorMessage('image')}
      </>
    ),
    UploadImageCarousel: (
      <>
        <UploadImage
          importedFiles={
            formik.values.images
              ?.filter((file: any) => {
                return typeof file === 'string';
              })
              ?.map((url: string, id: number) => {
                return { id, url, name: '' };
              }) ?? []
          }
          onChange={files => {
            const newFiles = files.map(file => {
              return 'url' in file ? file.url : file;
            });

            formik.setFieldValue('images', newFiles);
          }}
          descriptionText="Imagens do Carrossel. Para garantir a qualidade da visualização, as imagens do carrossel precisam ter dimensão mínima de 1366 x 600 pixels (a ordem das imagens no carrossel será a mesma mostrada abaixo)"
          multipleFiles={true}
          htmlFor="resource-images"
          limitFiles={8}
          required
        />
        {errorMessage('images')}
      </>
    ),
    UploadImageLibrary: (
      <>
        <UploadImage
          importedFiles={
            formik.values.banners
              ?.filter((file: any) => {
                return typeof file === 'string';
              })
              ?.map((url: string, id: number) => {
                return { id, url, name: '' };
              }) ?? []
          }
          onChange={files => {
            const newFiles = files.map(file => {
              return 'url' in file ? file.url : file;
            });

            formik.setFieldValue('banners', newFiles);
          }}
          descriptionText="Imagens da Biblioteca: Adicione ate 4 imagens. Para garantir a qualidade da visualização, as imagens precisam ter dimensão mínima de 1366 x 600 pixels"
          multipleFiles={true}
          htmlFor="resource-banners"
          limitFiles={4}
        />
        {errorMessage('banners')}
      </>
    ),
  };

  return (
    <Container>
      <h2 className="formName">{formName}</h2>
      <form onSubmit={formik.handleSubmit}>
        {fields.map(fieldName => (
          <div key={fieldName}>{fieldComponents[fieldName]}</div>
        ))}
        <div className="buttonsForm">
          <Button type="button" buttonType="secondary" onClick={onCancel}>
            Cancelar
          </Button>
          <Button type="submit" buttonType="primary">
            {formName === 'Novo Evento' || formName === 'Novo Roteiro' ? 'Criar' : 'Salvar'}
          </Button>
        </div>
      </form>
      <ConfirmationModal
        message="Atenção: Após o envio, não será possível a edição. Tem certeza de que quer enviar os dados?"
        title="Envio de Formulário"
        onConfirm={() => {
          setShowConfirmModal(false);
          openLoading();
          onSubmit(formik.values).finally(closeLoading);
        }}
        onCancel={() => setShowConfirmModal(false)}
        confirmText="Enviar"
        open={showConfirmModal}
        type="submit"
      />
    </Container>
  );
};

export default DynamicForm;
