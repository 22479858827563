import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridColumnVisibilityModel,
  GridToolbar,
} from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import editIcon from '../../../../assets/images/editIcon.svg';
import trashIcon from '../../../../assets/images/trashIcon.svg';
import { useLoading } from 'hooks/useLoading';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import Heading from 'components/Heading';
import INews, { NewsStatusAPI } from 'interfaces/INews';
import NewsService from 'services/NewsService';
import ConfirmationModal from 'components/ConfirmationModal';
import eyeIcon from 'assets/images/eyeGrayIcon.svg';

const BusinessList = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [newsData, setNewsData] = useState<INews[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
  });
  const [openModalDeleteSolicitationData, setOpenModalDeleteSolicitationData] = useState(false);
  const [item, setItem] = useState({} as INews);
  const history = useHistory();

  useEffect(() => {
    openLoading();
    const fetchNewsData = [fetchNews()];
    Promise.all(fetchNewsData).finally(() => closeLoading());
  }, []);

  const service = new NewsService();

  const fetchNews = async () => {
    const data = await service.findAll();
    setNewsData(data);
  };

  const stylingRowStatus = (status?: NewsStatusAPI) => {
    switch (status) {
      case NewsStatusAPI.PUBLISHED:
        return <span className="approvedBadge">Publicado</span>;
      case NewsStatusAPI.DRAFT:
        return <span className="reprovedBadge">Rascunho</span>;
      default:
        return '- - -';
    }
  };

  const businessColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Criação',
      flex: 1,
      maxWidth: 200,
      minWidth: 150,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
      valueFormatter: ({ value }) => {
        const date = new Date(value as string);
        const formattedDate = date.toLocaleDateString('pt-BR');
        const formattedTime = date.toLocaleTimeString('pt-BR').substring(0, 5);
        return `${formattedDate} - ${formattedTime}`.replace(',', '');
      },
    },
    {
      field: 'title',
      headerName: 'Título da Notícia',
      flex: 1,
      minWidth: 300,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'author',
      headerName: 'Nome do Autor',
      flex: 1,
      minWidth: 200,
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      maxWidth: 120,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<INews, NewsStatusAPI, keyof INews, GridTreeNodeWithRender>) =>
        stylingRowStatus(params.value),
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 1,
      maxWidth: 130,
      minWidth: 100,
      getActions: params => [
        <div key={params.id}>
          <button className="button-icon" title="Preview" onClick={() => window.open(`/news/${params.row.slug}`)}>
            <img src={eyeIcon} alt="Preview" />
          </button>
          <button
            className="button-icon"
            title="Editar"
            onClick={() => {
              history.push(`/admin/news-edit/${params.id}`);
            }}>
            <img src={editIcon} alt="Editar" />
          </button>
          <button className="button-icon" title="Excluir" onClick={newsDeleteSolicitation(params.id.toString())}>
            <img src={trashIcon} alt="Excluir" />
          </button>
        </div>,
      ],
      align: 'center',
      headerAlign: 'center',
      resizable: true,
    },
  ];

  const newsDeleteSolicitation = (id: string) => () => {
    openLoading();
    const newsItem = newsData.find(news => news.id === Number(id));
    if (newsItem) {
      setItem(newsItem);
    }
    setOpenModalDeleteSolicitationData(true);
    closeLoading();
  };

  return (
    <Container>
      <div className="header">
        <Heading>Lista de Notícias</Heading>
        <Button type="submit" buttonType="primary" onClick={() => history.push('/admin/add-news')}>
          Nova Notícia
        </Button>
      </div>

      <Grid sx={{ width: '100%', marginBottom: '50px', textAlign: 'center' }}>
        <DataGrid
          sx={{
            background: '#fff',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
            borderRadius: '18px',
            width: '100%',
          }}
          rows={newsData}
          columns={businessColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          className="dataGrid"
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              densitySelector: false,
              exportButton: false,
            },
          }}
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableColumnMenu
          disableDensitySelector
          disableVirtualization
        />
      </Grid>

      <ConfirmationModal
        open={openModalDeleteSolicitationData}
        title="Exclusão de Notícia"
        message="Você está excluindo uma notícia. Se continuar com a ação, não será possível recuperá-lá depois. Deseja continuar?"
        confirmText="Excluir"
        onConfirm={async () => {
          await service.remove(item.id);
          setOpenModalDeleteSolicitationData(false);
          await fetchNews();
        }}
        onCancel={() => setOpenModalDeleteSolicitationData(false)}
      />
    </Container>
  );
};

export default BusinessList;
