import React, { useEffect, useState } from 'react';
import { Container, FormList, FormListButtonBox, FormListRow, MainContentTitle } from './styles';
import Button from 'components/Button';
import MessageModal from 'common/MessageModal';
import TextArea from 'components/TextArea';
import UploadImage from 'components/UploadImage/field';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { newsInitialValues, newsValidationSchema } from './utils';
import { useHistory, useParams } from 'react-router';
import NewsService from 'services/NewsService';
import ImageService from 'services/ImageService';
import { useLoading } from 'hooks/useLoading';
import MainTitle from 'components/MainTitle';
import ConfirmationModal from 'components/ConfirmationModal';

const NewsAdminNewPage = (): JSX.Element => {
  const history = useHistory();
  const id = useParams<{ id: string }>();
  const [item, setItem] = useState([] as any);
  const { openLoading, closeLoading } = useLoading();
  const [showModal, setShowModal] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ title: '', message: '' });
  const [status, setStatus] = useState('' as string);
  const [modalAction, setModalAction] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formValues, setFormValues] = useState<any>({});

  const service = new NewsService();
  const imageService = new ImageService();

  useEffect(() => {
    if (id.id) {
      fetchNews();
    }
  }, [id.id]);

  const fetchNews = async () => {
    openLoading();
    const data = await service.findOne(Number(id.id));
    const imagesData = data?.images?.map((image: any) => image.image);
    setItem({ ...data, images: imagesData, image: [data.image], banner: [data.banner] });
    closeLoading();
  };

  const sendDraftStatus = () => {
    setStatus('draft');
  };

  const handleConfirmModal = (action: string, values: any) => {
    console.log(values);
    if (
      values.title &&
      values.subtitle &&
      values.author &&
      values.description &&
      values.banner.length > 0 &&
      values.image.length > 0
    ) {
      setModalAction(action);
      setShowConfirmationModal(true);
    } else {
      setMessageInfo({ title: 'Erro', message: 'Preencha todos os campos obrigatórios' });
      setShowModal(true);
    }
  };

  const publishNews = async (values: any) => {
    try {
      const [images, image, banner] = await Promise.all([
        imageService.uploadMultiple(values.images),
        imageService.uploadMultiple(values.image),
        imageService.uploadMultiple(values.banner),
      ]);

      const restoredImages = images.map((image: string) => ({
        image,
        description: '',
      }));

      const restoredObject = {
        ...values,
        images: [...restoredImages],
        image: image[0],
        banner: banner[0],
        slug: values.title.toLowerCase().replace(/ /g, '-'),
        date: id.id ? values.date : new Date().toISOString(),
        link: '',
        status: status === 'draft' ? 'draft' : 'published',
      };

      id.id ? await service.update(Number(id.id), restoredObject) : await service.create(restoredObject);

      setMessageInfo({ title: 'Salvamento de Rascunho', message: 'Rascunho salvo com sucesso!' });
      setShowModal(true);
    } catch (error) {
      setMessageInfo({ title: 'Erro', message: 'Erro ao salvar os dados' });
      setShowModal(true);
    }
  };

  const onSubmit = async (values: any) => {
    openLoading();
    try {
      await publishNews(values);
      closeLoading();
    } catch (error) {
      setMessageInfo({ title: 'Erro', message: 'Erro ao salvar os dados' });
      setShowModal(true);
      closeLoading();
    }
  };

  const handlePublish = async () => {
    setStatus('published');

    try {
      await onSubmit(formValues);

      setFormValues(newsInitialValues);

      setMessageInfo({ title: 'Publicação', message: 'Notícia publicada com sucesso!' });
      setShowModal(true);
    } catch (error) {
      setMessageInfo({ title: 'Erro', message: 'Erro ao publicar a notícia' });
      setShowModal(true);
    }
  };

  const handleConfirmation = () => {
    if (modalAction === 'publish') {
      handlePublish();
    }
    setShowConfirmationModal(false);
  };

  const closeModal = () => {
    setShowModal(false);

    if (messageInfo.title === 'Publicação' && 'Salvamento de Rascunho') {
      history.push('/admin/news');
    }
  };

  return (
    <>
      <MainTitle>Gestão de Notícias</MainTitle>
      <Container>
        <MainContentTitle>{id.id ? 'Editar Notícia' : 'Nova Notícia'}</MainContentTitle>
        <Formik
          initialValues={{
            ...newsInitialValues,
            ...item,
          }}
          validationSchema={newsValidationSchema}
          onSubmit={onSubmit}
          enableReinitialize>
          {({ values }) => (
            <Form>
              <FormList>
                <FormListRow className="col-4">
                  <label htmlFor="title">
                    Título da Notícia <span>*</span>
                  </label>
                  <Field id="title" name="title" type="text" />
                  <div style={{ position: 'absolute', marginTop: '70px' }}>
                    <ErrorMessage name="title" />
                  </div>
                </FormListRow>
                <FormListRow className="col-4">
                  <label htmlFor="subtitle">
                    Subtítulo da Notícia <span>*</span>
                  </label>
                  <Field id="subtitle" name="subtitle" type="text" />
                  <div style={{ position: 'absolute', marginTop: '70px' }}>
                    <ErrorMessage name="subtitle" />
                  </div>
                </FormListRow>
                <FormListRow className="col-4">
                  <label htmlFor="author">
                    Nome do Autor <span>*</span>
                  </label>
                  <Field id="author" name="author" type="text" />
                  <div style={{ position: 'absolute', marginTop: '70px' }}>
                    <ErrorMessage name="author" />
                  </div>
                </FormListRow>

                <FormListRow className="col-4">
                  <TextArea maxLength={3000} name="description" title="Descrição" lg />
                  <div style={{ position: 'absolute', marginTop: '340px' }}>
                    <ErrorMessage name="description" />
                  </div>
                </FormListRow>
                <FormListRow className="col-4 uploadImage">
                  <UploadImage
                    descriptionText="Imagem Principal: Adicione 1 imagem. Para garantir a qualidade da visualização, a imagem precisam ter dimensão mínima de 1366 x 600 pixels"
                    multipleFiles={false}
                    limitFiles={1}
                    htmlFor="banner"
                    name="banner"
                  />
                  <div className="MessageError">
                    <ErrorMessage name="banner" />
                  </div>
                </FormListRow>
                <FormListRow className="col-4 uploadImage">
                  <UploadImage
                    descriptionText="Imagem do Card: Adicione 1 imagem. Para garantir a qualidade da visualização, as imagens precisam ter dimensão mínima de 500 x 600 pixels"
                    multipleFiles={false}
                    limitFiles={1}
                    htmlFor="image"
                    name="image"
                  />
                  <div className="MessageError">
                    <ErrorMessage name="image" />
                  </div>
                </FormListRow>
                <FormListRow className="col-4">
                  <UploadImage
                    descriptionText="Imagens da Biblioteca: Adicione até 9 imagens. Para garantir a qualidade da visualização, as imagens precisam ter dimensão mínima de 572 x 432 pixels"
                    multipleFiles
                    limitFiles={9}
                    htmlFor="images"
                    name="images"
                  />
                </FormListRow>

                <FormListRow className="col-4">
                  {id.id ? (
                    <FormListButtonBox>
                      <Button type="button" buttonType="secondary" onClick={() => history.push('/admin/news')}>
                        Cancelar
                      </Button>
                      <Button type="submit" buttonType="secondary" onClick={sendDraftStatus}>
                        Salvar como Rascunho
                      </Button>
                      <Button
                        type="submit"
                        onClick={() => {
                          handleConfirmModal('publish', values);
                        }}>
                        Salvar e Publicar
                      </Button>
                    </FormListButtonBox>
                  ) : (
                    <FormListButtonBox>
                      <Button type="button" buttonType="secondary" onClick={() => history.push('/admin/news')}>
                        Cancelar
                      </Button>
                      <Button type="submit" buttonType="secondary" onClick={sendDraftStatus}>
                        Salvar como rascunho
                      </Button>
                      <Button
                        type="submit"
                        onClick={() => {
                          handleConfirmModal('publish', values);
                        }}>
                        Publicar
                      </Button>
                    </FormListButtonBox>
                  )}
                </FormListRow>
              </FormList>
            </Form>
          )}
        </Formik>
        {showConfirmationModal && (
          <ConfirmationModal
            open={showConfirmationModal}
            onCancel={() => setShowConfirmationModal(false)}
            onConfirm={handleConfirmation}
            title="Publicação de Notícia"
            message="Tem certeza de que deseja publicar essa notícia?"
            confirmText="Publicar"
          />
        )}

        {showModal && !showConfirmationModal && (
          <MessageModal
            isOpen={showModal}
            title={messageInfo.title}
            message={messageInfo.message}
            onClose={closeModal}
          />
        )}
      </Container>
    </>
  );
};

export default NewsAdminNewPage;
