import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Grid } from '@mui/material';
import { useLoading } from 'hooks/useLoading';
import { auth } from 'utils/firebase';
import yup from 'utils/validation';
import MessageModal from 'common/MessageModal';
import Heading from 'components/Heading';
import Button from 'components/Button';
import Field from 'components/Field';
import ErrorMessage from 'components/ErrorMessage';
import { Container, Div, Panel } from './styles';

interface IFormValues {
  email: string;
}

const ForgotPassword = (): JSX.Element => {
  const { openLoading, closeLoading } = useLoading();
  const [counter, setCounter] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (counter === 0) {
      return;
    }

    setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const handleFormSubmit = async (formValues: IFormValues) => {
    sendPasswordResetEmail(formValues.email);
  };

  const sendPasswordResetEmail = async (email: string) => {
    openLoading();

    try {
      await auth.sendPasswordResetEmail(email);
      setUserEmail(email);
      setCounter(60);
    } catch (err) {
      setUserEmail('Erro ao enviar e-mail com as instruções para a redefinição de senha');
      setErrorMessage('Error');
    } finally {
      closeLoading();
    }
  };

  const initialValues = { email: '' };
  const validationSchema = yup.object({ email: yup.string().email().required() });

  return (
    <Container>
      <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ minHeight: '70vh' }}>
        <Grid item xs={12} sm={10} md={8} lg={3}>
          {!userEmail ? (
            <Formik<IFormValues>
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}>
              <Form>
                <Panel>
                  <Div>
                    <Heading>Encontre sua conta:</Heading>
                  </Div>
                  <Div>Informe seu e-mail para enviarmos as instruções de redefinição de senha.</Div>
                  <Div>
                    <Field name="email" />
                    <ErrorMessage name="email" />
                  </Div>
                  <Div>
                    <Button type="submit">Próximo</Button>
                  </Div>
                </Panel>
              </Form>
            </Formik>
          ) : (
            <Panel>
              <Div>
                <Heading>E-mail de redefinição de senha enviado!</Heading>
              </Div>
              <Div>Enviamos um e-mail com as instruções para a definição de uma nova senha.</Div>
              <Div>
                Atenção! Não se esqueça de verificar se o e-mail foi para sua caixa de spam. Caso não receba o e-mail,
                solicite o reenvio abaixo. Se o problema persistir, entre em contato pelo número (73) 8866-7507.
              </Div>
              {counter ? <Div>Solicitar o reenvio em {counter} segundos.</Div> : <Div>Solicitar o reenvio</Div>}
              <Div>
                <Button buttonType={counter ? 'disabled' : 'primary'} onClick={() => sendPasswordResetEmail(userEmail)}>
                  Reenviar
                </Button>
              </Div>
            </Panel>
          )}
        </Grid>
      </Grid>
      <MessageModal
        title="Aviso"
        message={errorMessage}
        isOpen={Boolean(errorMessage)}
        onClose={() => setErrorMessage('')}
      />
    </Container>
  );
};

export default ForgotPassword;
