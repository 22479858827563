import React, { useRef, useState } from 'react';
import { ButtonsDiv, Divider, FormRow, InputField, SignUpForm } from './styles';
import InputPassword from 'common/InputPassword/InputPassword';
import Button from 'components/Buttom/Button';
import FieldMask from 'components/FieldMask';
import { Formik, Field, Form, FormikProps, FormikValues } from 'formik';
import SelectComponent from 'components/Select';
import { states, StatesEnum, StatesSelectProps, validateCNPJ, validateCPF } from '../../utils';
import ErrorMessage from 'components/ErrorMessage';
import yup from 'utils/validation';

const validationSchema = yup.object({
  document: yup.object({
    cpf: yup
      .string()
      .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CPF completo')
      .test('is-valid-cpf', 'Informe um CPF válido', value => validateCPF(value || ''))
      .required(),
    cnpj: yup
      .string()
      .matches(/^([\w\.\-\/]{18}|[\w\.\-\/]{14})$/, 'Informe um CNPJ completo')
      .test('is-valid-cnpj', 'Informe um CNPJ válido', value => validateCNPJ(value || ''))
      .required(),
  }),
  email: yup.string().email().required(),
  name: yup.string().required(),
  password: yup.string().min(8).required(),
  extraInfo: yup.object({
    cadastur: yup.string(),
    companyName: yup.string().required(),
    phone: yup.string().length(16).required(),
    tradeName: yup.string().required(),
    address: yup.object({
      additionalData: yup.string(),
      city: yup.string().required(),
      neighborhood: yup.string().required(),
      number: yup.string().required(),
      state: yup.string().required(),
      street: yup.string().required(),
      zipCode: yup.string().length(9).required(),
    }),
  }),
});

const initialValues = {
  document: {
    cpf: '',
    cnpj: '',
  },
  email: '',
  name: '',
  password: '',
  extraInfo: {
    cadastur: '',
    companyName: '',
    phone: '',
    tradeName: '',
    address: {
      additionalData: '',
      city: '',
      neighborhood: '',
      number: '',
      state: '',
      street: '',
      zipCode: '',
    },
  },
};

interface Props {
  onCancel: () => void;
  onSubmit: (values: FormikValues) => void;
}

const PjForm = (props: Props): JSX.Element => {
  const pjForm = useRef<FormikProps<FormikValues>>(null);
  const [selectedState, setSelectedState] = useState<string>('');

  const StatesSelect: React.FC<StatesSelectProps> = ({ field, form }) => {
    const { name } = field;
    return (
      <SelectComponent
        label=""
        options={states.map(state => ({ label: state.label, value: state.value }))}
        onChange={value => {
          if (typeof value === 'string') {
            form.setFieldValue(name, value as StatesEnum);
            setSelectedState(value as string);
          }
        }}
        name="state"
        selected={[selectedState]}
      />
    );
  };

  return (
    <Formik
      innerRef={pjForm}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={props.onSubmit}>
      <SignUpForm>
        <header>
          <h2>Dados da empresa:</h2>
          <span>2/2</span>
        </header>
        <Form>
          <FormRow>
            <InputField id="cadastur-input">
              <div>
                <label htmlFor="">
                  CNPJ <span>*</span>
                </label>
                <FieldMask type="cnpj">
                  <Field type="text" name="document.cnpj" />
                </FieldMask>
              </div>
              <ErrorMessage name="document.cnpj" />
            </InputField>
            <InputField id="company-name-input">
              <div>
                <label htmlFor="">
                  Nome Fantasia<span>*</span>
                </label>
                <Field name="extraInfo.tradeName" />
              </div>
              <ErrorMessage name="extraInfo.tradeName" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="social-reason-input">
              <div>
                <label htmlFor="">
                  Razão Social<span>*</span>
                </label>
                <Field name="extraInfo.companyName" />
              </div>
              <ErrorMessage name="extraInfo.companyName" />
            </InputField>
          </FormRow>
          <Divider />
          <h2>Dados do responsável:</h2>
          <FormRow>
            <InputField id="full-name-input">
              <div>
                <label htmlFor="">
                  Nome Completo<span>*</span>
                </label>
                <Field name="name" />
              </div>
              <ErrorMessage name="name" />
            </InputField>
            <InputField id="cpf-input">
              <div>
                <label htmlFor="">
                  CPF<span>*</span>
                </label>
                <FieldMask type="cpf">
                  <Field type="text" name="document.cpf" />
                </FieldMask>
              </div>
              <ErrorMessage name="document.cpf" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="cellphone-input">
              <div>
                <label htmlFor="">
                  Telefone ou celular<span>*</span>
                </label>
                <FieldMask type="phone">
                  <Field name="extraInfo.phone" />
                </FieldMask>
              </div>
              <ErrorMessage name="extraInfo.phone" />
            </InputField>
            <InputField id="email-input">
              <div>
                <label htmlFor="">
                  E-mail<span>*</span>
                </label>
                <FieldMask type="email">
                  <Field type="text" name="email" />
                </FieldMask>
              </div>
              <ErrorMessage name="email" />
            </InputField>
            <InputField id="cep-input">
              <div>
                <label htmlFor="">
                  CEP<span>*</span>
                </label>
                <FieldMask type="zipcode">
                  <Field name="extraInfo.address.zipCode" />
                </FieldMask>
              </div>
              <ErrorMessage name="extraInfo.address.zipCode" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="address-input">
              <div>
                <label htmlFor="">
                  Endereço<span>*</span>
                </label>
                <Field name="extraInfo.address.street" />
              </div>
              <ErrorMessage name="extraInfo.address.street" />
            </InputField>
            <InputField id="number-input">
              <div>
                <label htmlFor="">
                  Número<span>*</span>
                </label>
                <Field name="extraInfo.address.number" />
              </div>
              <ErrorMessage name="extraInfo.address.number" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="complement-input">
              <div>
                <label htmlFor="">Complemento</label>
                <Field name="extraInfo.address.additionalData" />
              </div>
              <ErrorMessage name="extraInfo.address.additionalData" />
            </InputField>
            <InputField id="neighborhood-input">
              <div>
                <label htmlFor="">
                  Bairro<span>*</span>
                </label>
                <Field name="extraInfo.address.neighborhood" />
              </div>
              <ErrorMessage name="extraInfo.address.neighborhood" />
            </InputField>
          </FormRow>
          <FormRow>
            <InputField id="city-input">
              <div>
                <label htmlFor="">
                  Cidade<span>*</span>
                </label>
                <Field name="extraInfo.address.city" />
              </div>
              <ErrorMessage name="extraInfo.address.city" />
            </InputField>
            <InputField id="state-input">
              <div>
                <label htmlFor="">
                  Estado<span>*</span>
                </label>
                <Field component={StatesSelect} name="extraInfo.address.state" />
              </div>
              <ErrorMessage name="extraInfo.address.state" />
            </InputField>
            <InputField id="password-input">
              <div>
                <label htmlFor="">
                  Senha<span>*</span>
                </label>
                <InputPassword name={'password'} />
              </div>
              <ErrorMessage name="password" />
            </InputField>
          </FormRow>
          <Divider />
          <ButtonsDiv>
            <Button onClick={props.onCancel} text="VOLTAR" />
            <Button
              onClick={() => {
                props.onCancel();
                pjForm?.current?.setValues(initialValues);
              }}
              text="CANCELAR"
            />
            <Button text="CRIAR" type="submit" />
          </ButtonsDiv>
        </Form>
      </SignUpForm>
    </Formik>
  );
};

export default PjForm;
