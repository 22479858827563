import React, { useState } from 'react';
import { Container } from './styles';
import Button from 'components/Buttom/Button';
import { MenuItem, Select } from '@mui/material';
import PfForm from './components/PfForm';
import PjForm from './components/PjForm';

interface Props {
  handleSubmit(type: string, data: Record<string, unknown>): Promise<void>;
  form?: {
    current?: {
      setValues: (values: Record<string, unknown>) => void;
    };
  };
}

const SignupTourOrganizer = (props: Props): JSX.Element => {
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [seturOrganizer, setSeturOrganizer] = useState<string>('');
  const [show, setShow] = useState<string>('');
  const { handleSubmit } = props;

  const handleChange = (value: string) => {
    setDisabledButton(false);
    setSeturOrganizer(value as string);
  };

  const verifyTourOrganizerType = () => {
    if (seturOrganizer === 'PF') {
      setShow('PF');
    } else {
      setShow('PJ');
    }
  };

  const submitPfForm = async (data: Record<string, unknown>) => {
    await handleSubmit('tour-pf', data);
  };

  const submitPjForm = async (data: Record<string, unknown>) => {
    await handleSubmit('tour-pj', data);
  };

  return (
    <>
      {!show && (
        <Container>
          <div>
            <h2>Preencha as informações abaixo</h2>
            <span>1/2</span>
          </div>
          <label htmlFor="">
            Tipo de Organizador <span>*</span>
          </label>
          <Select className="dropdown" onChange={e => handleChange(e.target.value as string)}>
            <MenuItem value="PF">Pessoa Física</MenuItem>
            <MenuItem value="PJ">Pessoa Jurídica</MenuItem>
          </Select>
          <Button text="PRÓXIMO" onClick={verifyTourOrganizerType} disabled={disabledButton} />
        </Container>
      )}
      {show === 'PF' && <PfForm onCancel={() => setShow('')} onSubmit={submitPfForm} />}
      {show === 'PJ' && <PjForm onCancel={() => setShow('')} onSubmit={submitPjForm} />}
    </>
  );
};

export default SignupTourOrganizer;
